import React from "react";
import { Link } from "gatsby";

import Frame from "~/interface/layout/frame";
import Logo from "~/interface/identity/logo";
import SEO from "~/helpers/seo";

const JobsPage = () => {
  return (
    <Frame className="bg-white text-black">
      <div className="py-20 px-8 sm:px-[128px]">
        <Link to="/">
          <Logo fill="#121212" className="mx-auto md:mx-0" />
        </Link>
      </div>

      <div className="mx-auto pb-20 px-8 sm:px-20 ">
        <div className="text-4xl font-bold text-center mb-5">採用情報</div>
        <h1 className="mb-10 mx-auto text-lg leading-[34px] tracking-[.06em] text-center">
          私たちに関心をお寄せ頂きありがとうございます。
          <br />
          Artefactでは、現在以下の職種の募集を行なっております。
        </h1>
        <p className="text-2xs font-bold text-center mb-3">
          まずは話を聞いてみる
        </p>
        <div className="text-center">
          <a
            href="mailto:recruit@artefact.co.jp"
            className="inline-block mx-auto"
          >
            <div className="flex gap-3 bg-black py-2 px-4 rounded font-bold text-white items-center">
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.75 3.75V14.25C20.75 14.8467 20.5129 15.419 20.091 15.841C19.669 16.2629 19.0967 16.5 18.5 16.5H3.5C2.90326 16.5 2.33097 16.2629 1.90901 15.841C1.48705 15.419 1.25 14.8467 1.25 14.25V3.75M20.75 3.75C20.75 3.15326 20.5129 2.58097 20.091 2.15901C19.669 1.73705 19.0967 1.5 18.5 1.5H3.5C2.90326 1.5 2.33097 1.73705 1.90901 2.15901C1.48705 2.58097 1.25 3.15326 1.25 3.75M20.75 3.75V3.993C20.75 4.37715 20.6517 4.75491 20.4644 5.0903C20.2771 5.42569 20.0071 5.70754 19.68 5.909L12.18 10.524C11.8252 10.7425 11.4167 10.8582 11 10.8582C10.5833 10.8582 10.1748 10.7425 9.82 10.524L2.32 5.91C1.99292 5.70854 1.72287 5.42669 1.53557 5.0913C1.34827 4.75591 1.24996 4.37815 1.25 3.994V3.75"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="tracking-[0.06em]">recruit@artefact.co.jp</span>
            </div>
          </a>
        </div>
      </div>

      <div className="py-16 pb-20 px-8 sm:px-20 max-w-[800px] mx-auto border-t-[0.5px] border-gray-light">
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-3 text-center">
            セールス・事業開発
          </h2>
          <p className="text-left leading-7">
            自社運営メディア『unprinted®』の収益化施策を企画、主にデザイナーを採用したい企業に向けて求人広告やタイアップ記事広告などを提案する企画営業職です。
            メディアの収益化にゼロから取り組むため、営業企画から広告商品の開発、新規開拓、顧客との関係構築まで幅広く携わることのできるポジションとなっています。
          </p>
        </div>

        <dl>
          <dt className="font-bold mb-2">募集職種</dt>
          <dd className="mb-10">
            <p>Webメディア収益化のためのセールス・事業開発</p>
          </dd>

          <dt className="font-bold mb-2">業務内容</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">広告企画や商品の開発</li>
              <li className="mb-3">新規広告主・代理店の開拓</li>
              <li className="mb-3">デザイナー求人企業への営業</li>
              <li>クライアントの課題ヒアリング、分析</li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">必須条件</dt>
          <dd className="mb-10">
            <p>基礎的なPCスキル（Microsoftツール、Googleツールほか）</p>
          </dd>

          <dt className="font-bold mb-2">仕事を通じて得られるもの</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">Webメディアの収益化経験</li>
              <li>
                デザイナー、エンジニアと連携しながらプロダクトを開発する経験
              </li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">歓迎条件</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">WEB広告営業の経験者</li>
              <li className="mb-3">メディア・広告業界経験者</li>
              <li className="mb-3">IT業界・デザイン業界経験者</li>
              <li className="mb-3">論理的思考に基づいて行動できる方</li>
              <li>事業運営に関心のある方</li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">給与</dt>
          <dd className="mb-10">
            <p className="mb-3">
              月給30万 - 40万円（スキル、経験などを考慮の上、決定致します）
            </p>
            <p>業績に応じた決算賞与</p>
          </dd>

          <dt className="font-bold mb-2">こんな方を求めています</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">主体的に業務に取り組める方</li>
              <li className="mb-3">事業開発にゼロから取り組みたい方</li>
              <li className="mb-3">
                新領域において知識やスキルを吸収する意欲、チャレンジ精神のある方
              </li>
              <li className="mb-3">Webメディア分野に興味がある方</li>
              <li>時間や場所にとらわれず柔軟に働きたい方</li>
            </ul>
          </dd>
        </dl>
      </div>

      <div className="py-16 px-8 sm:px-20 max-w-[800px] mx-auto border-t-[0.5px] border-gray-light">
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-3 text-center">編集・企画</h2>
          <p className="text-left  leading-7">
            デジタルデザイナーのためのWebメディア『unprinted®』にて編集・企画担当者を募集しています。
            トラフィックの獲得やユーザーロイヤリティの向上など、複数の目標にもとづいた多様な企画を行うことができるポジションとなっています。
            デザイナーとエンジニアを連携して、記事コンテンツのみならずツールの開発などを企画・提案することも可能で、自社開発のWebメディアという特徴を活かした自由なコンテンツ作りができる環境です。
          </p>
        </div>

        <dl>
          <dt className="font-bold mb-2">募集職種</dt>
          <dd className="mb-10">
            <p>企画・編集者</p>
          </dd>

          <dt className="font-bold mb-2">業務内容</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">
                デザイナー向けWebメディアにおけるコンテンツの企画・戦略策定
              </li>
              <li className="mb-3">ライターが執筆した記事の編集</li>
              <li className="mb-3">記事挿絵デザインのディレクション</li>
              <li className="mb-3">SNSコンテンツの制作・運用</li>
              <li>サイト指標の分析・レポーティング</li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">必須条件</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">Webメディアでの企画・編集担当経験</li>
              <li>デジタル分野のデザイン業務に関する理解</li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">仕事を通じて得られるもの</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">
                データドリブンにWebメディアをグロースさせる経験
              </li>
              <li className="mb-3">プロダクト開発企画の経験</li>
              <li>SEOに関する実践的な知見</li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">歓迎条件</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">論理的思考に基づいて行動できる方</li>
              <li>事業運営に関心のある方</li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">給与</dt>
          <dd className="mb-10">
            <p className="mb-3">
              月給20万 - 40万円（スキル、経験などを考慮の上、決定致します）
            </p>
            <p>業績に応じた決算賞与</p>
          </dd>

          <dt className="font-bold mb-2">こんな方を求めています</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">デザイン分野に興味関心の高い方</li>
              <li className="mb-3">Webメディア分野に興味がある方</li>
              <li>時間や場所にとらわれず柔軟に働きたい方</li>
            </ul>
          </dd>
        </dl>
      </div>

      <div className="py-16 px-8 sm:px-20 max-w-[800px] mx-auto border-t-[0.5px] border-gray-light">
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-3 text-center">
            デザインライター
          </h2>
          <p className="text-left  leading-7">
            デジタルデザイナーのためのWebメディア『unprinted®』にてデザイナー向けの記事を書いてみたいデザイナーさんを募集しています。
            デザイナー向けのTipsや最近の発見を始め、おすすめの本などご自身のデザイン業務での経験を活かしてコンテンツを書いてみたい方にぴったりのポジションです。
            月1記事から依頼可能ですので、ご都合に合わせて書きたいトピックがある時に書いて頂くような形での関わり方も大歓迎です。
          </p>
        </div>

        <dl>
          <dt className="font-bold mb-2">募集職種</dt>
          <dd className="mb-10">
            <p>デザインライター</p>
          </dd>

          <dt className="font-bold mb-2">業務内容</dt>
          <dd className="mb-10">
            <p>デザイナー向けの記事の企画・執筆</p>
          </dd>

          <dt className="font-bold mb-2">記事企画の例</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc mb-3">
              <li className="mb-3">デザイナーにおすすめの雑誌5選</li>
              <li className="mb-3">Figmaを使ったプロトタイピングのポイント</li>
              <li className="mb-3">気分転換におすすめのリラックスグッズ特集</li>
              <li className="mb-3">
                デザイナーにおすすめのクリスマスプレゼント特集
              </li>
              <li>Zoomでのユーザーインタビューで失敗したポイント</li>
            </ul>
            <p>
              など、記事内容は編集担当者と相談しながら都度決定します。
              直接デザイン業務に関係のないトピックでもデザイナーの視点が含まれていれば大丈夫です。
            </p>
          </dd>

          <dt className="font-bold mb-2">必須条件</dt>
          <dd className="mb-10">
            <p>
              現在UIデザイン、UXデザインなどデジタル分野でのデザイン業務に携わっている方
            </p>
          </dd>

          <dt className="font-bold mb-2">仕事を通じて得られるもの</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">
                デザインに関する自身の視点や意見を発信する経験
              </li>
              <li className="mb-3">
                ご自身の名前でコンテンツを発信できるので、ポートフォリオへの掲載などにご利用頂くことも可能です。
              </li>
            </ul>
          </dd>

          <dt className="font-bold mb-2">報酬</dt>
          <dd className="mb-10">
            <p className="mb-3">1記事2万円 ~</p>
          </dd>

          <dt className="font-bold mb-2">こんな方を求めています</dt>
          <dd className="mb-10">
            <ul className="py-2 px-6 list-outside list-disc">
              <li className="mb-3">
                メディア上で発信したいオリジナルのアイデアや経験のある方
              </li>
              <li className="mb-3">Webメディア分野に興味がある方</li>
              <li>時間や場所にとらわれず柔軟に働きたい方</li>
            </ul>
          </dd>
        </dl>
      </div>

      <div className="flex flex-col items-center gap-5 py-10 px-[68px] border-t-[0.5px] border-gray-light">
        <p className="text-2xs leading-[26px] text-center">
          東京都目黒区駒場4-6-2 Y-5 yamagataya 501
          <br /> 03-4588-9508, 株式会社Artefact
        </p>
        <p className="text-gray-darker text-3xs text-center">
          Copyright © Artefact Inc. All rights Reserved.
        </p>
      </div>
    </Frame>
  );
};

export default JobsPage;

export const Head = () => {
  return (
    <SEO
      title="採用情報 | 株式会社Artefact"
      description="Artefactでは、共にデジタルプロダクトを作るメンバーを募集しています。「楽しむこと」を大切にするチームで一緒に働いてみませんか？まずはメールにてお気軽にお問い合わせください。"
      url="/jobs/"
    />
  );
};
